var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled, { css } from 'styled-components';
import { Stack } from '../Stack';
import { Body1, Body2 } from '../Typography/Body';
import { addOpacity } from '../_utils/addOpacity';
export const Alert = ({ actions, variant = 'neutral', children, mb = '0', className }) => (React.createElement(StyledCard, { variant: variant, marginBottom: mb, className: className },
    children, actions === null || actions === void 0 ? void 0 :
    actions.map((a) => (React.createElement(StyledAction, { key: a.text, as: "button", onClick: a.onClick }, a.text)))));
export const AlertTitle = (_a) => {
    var { children, icon } = _a, rest = __rest(_a, ["children", "icon"]);
    return (React.createElement(StyledStack, { mb: { xs: '0.8rem', sm: '0.8rem', md: '1.6rem', lg: '1.6rem' }, alignItems: "center", flexDirection: "row", gap: "0.8rem" },
        icon,
        React.createElement(Body1, Object.assign({ mb: "0", semibold: true }, rest), children)));
};
export const AlertMessage = (_a) => {
    var { as, children } = _a, rest = __rest(_a, ["as", "children"]);
    switch (as) {
        case 'Body1':
            return React.createElement(Body1, Object.assign({}, rest), children);
        case 'Body2':
            return React.createElement(Body2, Object.assign({}, rest), children);
        default:
            return React.createElement(Body1, Object.assign({}, rest), children);
    }
};
const StyledCard = styled.div `
  border-radius: 0.8rem;
  border: 2px solid;
  padding: 1.6rem;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  ${({ theme, variant }) => {
    switch (variant) {
        case 'neutral':
            return css `
          background: ${theme.colors.background.medium};
          border-color: ${theme.colors.grey.medium};
        `;
        case 'warning':
            return css `
          background: ${addOpacity(theme.colors.danger.medium, 10)};
          border-color: ${theme.colors.danger.medium};
        `;
        case 'success':
            return css `
          background: ${theme.colors.success.light};
          border-color: ${theme.colors.success.dark};
        `;
        case 'informative':
            return css `
          /* custom colour due to limitations of colours in our DS */
          background: #ebf2f4;
          border-color: #ebf2f4;
        `;
        default:
            return css `
          background: ${theme.colors.background.medium};
          border-color: ${theme.colors.background.medium};
        `;
    }
}};
`;
const StyledStack = styled(Stack) `
  svg {
    flex-shrink: 0;
  }
`;
const StyledAction = styled(Body1) `
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0 1.6rem 0 0;
  margin: 0;
  cursor: pointer;
`;
